<!--  -->
<template>
  <div class='echart-view' ref="barChartY"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: '',
  props: {
    data: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {
    data: {
      handler(val) {
        this.init(val.dataX, val.dataY)
      },
      deep: true,
    },
  },
  methods: {
    init(dataX, dataY) {
      var chartDom = this.$refs['barChartY']
      var myChart = echarts.init(chartDom)
      var option
      option = {
        grid: {
          left: '22%',
          right: '4%',
          bottom: '4%',
          top: '10%',
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          type: 'category',
          inverse: true,
          //隐藏y轴轴线
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(246, 230, 123, 0.5)',
            },
          },
          axisTick: {
            //隐藏刻度线
            show: false,
          },

          splitLine: {
            show: false,
          },
          //刻度轴标签
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: this.transformFontSize(22),
              color: '#FFF',
              fontWeight: 500,
            },
          },
          data: dataX,
        },
        series: [
          {
            data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
            type: 'bar',
            stack: 'Ad',
            barWidth: 20,
            itemStyle: {
              opacity: 0,
            },
          },
          {
            type: 'bar',
            stack: 'Ad',
            barWidth: 20,
            itemStyle: {
              color: function (params) {
                // 根据索引设置不同的渐变色
                var colorList = [
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(212, 76, 58, 0.9)' },
                    { offset: 1, color: 'rgba(255, 84, 79, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(243, 114, 0,  0.9)' },
                    { offset: 1, color: 'rgba(243, 114, 0, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(238, 200, 0,  0.9)' },
                    { offset: 1, color: 'rgba(238, 200, 0, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(123, 246, 219,  0.9)' },
                    { offset: 1, color: 'rgba(123, 246, 219, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(123, 161, 246,  0.9)' },
                    { offset: 1, color: 'rgba(123, 161, 246, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(178, 0, 252,  0.9)' },
                    { offset: 1, color: 'rgba(178, 0, 252, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(3, 169, 15,  0.9)' },
                    { offset: 1, color: 'rgba(3, 169, 15, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(0, 107, 252,  0.9)' },
                    { offset: 1, color: 'rgba(0, 107, 252, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(122, 2, 241,  0.9)' },
                    { offset: 1, color: 'rgba(122, 2, 241, 0)' },
                  ]),
                  new echarts.graphic.LinearGradient(1, 0, 0, 0, [
                    { offset: 0, color: 'rgba(147, 138, 58,  0.9)' },
                    { offset: 1, color: 'rgba(147, 138, 58, 0)' },
                  ]),
                  // 添加更多的渐变色...
                ]
                return colorList[params.dataIndex]
              },
            },
            data: dataY,
          },
          {
            name: '', //头部
            type: 'pictorialBar',
            stack: 'Ad',
            symbolSize: [10, 22],
            // symbolOffset: [40, 0],
            symbol: 'path://M10,-50 L58,-54 L40,46 L-10,52 Z',
            z: 12,
            symbolPosition: 'end',
            symbolRotate: -10,
            itemStyle: {
              // opacity: 0.5,
              color: function (params) {
                let index = params.dataIndex
                let colors = [
                  '#D44C3A',
                  '#F37200',
                  '#EEC800',
                  '#7BF6DB',
                  '#7BA1F6',
                  '#B200FC',
                  '#03A90F',
                  '#006BFC',
                  '#7D00FC',
                  '#938A3A',
                ]
                // 根据柱状图的索引设置不同的背景色
                return colors[index]
              },
            },
            data: [11.1, 10.1, 9.1, 8.1, 7.1, 6.1, 5.1, 4.1, 3.1, 2.1, 1.1],
          },
        ],
      }
      option && myChart.setOption(option)
    },
    transformFontSize(fontsize) {
      const width = window.screen.width
      const ration = width / 1920
      return parseInt((fontsize * ration).toString())
    },
  },
  created() {},
  mounted() {
    this.init(this.data.dataX, this.data.dataY)
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.echart-view {
  width: 100%;
  height: 100%;
}
</style>